import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { BarcodeFormat } from '@zxing/library';
import { Subscription } from 'rxjs';

// service
import { Logger } from 'app/services/logger.service';
import { ReaderService } from '../services/reader.service';

const log = new Logger('QrScannerComponent');

@Component({
    selector: 'app-scanner',
    templateUrl: './scanner.component.html',
    styleUrls: ['./scanner.component.scss']
})

export class ScannerComponent implements OnInit {

    @Output() notifyParent: EventEmitter<any> = new EventEmitter<any>();

    tryHarder = false;
    scannerTypeSelected: any;
    availableDevices: MediaDeviceInfo[] | undefined;
    currentDevice: MediaDeviceInfo | undefined;

    formatsEnabled: BarcodeFormat[] = [
        BarcodeFormat.CODE_128,
        BarcodeFormat.DATA_MATRIX,
        BarcodeFormat.EAN_13,
        BarcodeFormat.QR_CODE,
    ];

    hasDevices = false;
    hasPermission = false;

    qrResultString = '';

    subscription: Subscription;
    action: any;
    ready = false;

    constructor(
        private qrReader: ReaderService,
    ) {
    }

    ngOnInit(): void {
    }

    onResult(textResult: string) {
        this.notifyParent.emit(textResult);
    }

    onDeviceSelectChange(event: any) {
        if (this.availableDevices) {
            const selected = event.target.value;
            const device = this.availableDevices.find(x => x.deviceId === selected);
            this.currentDevice = device || undefined;
        }
    }

    onHasPermission(has: boolean) {
        this.hasPermission = has;
    }

    onCamerasFound(devices: MediaDeviceInfo[]): void {
        this.availableDevices = devices;
        this.hasDevices = Boolean(devices && devices.length);
        this.ready = true;
    }

    onCodeResult(resultString: string) {
        this.qrResultString = resultString;
        this.onResult(this.qrResultString);
    }

    public onEvent(e: any): void {
        if (e && e.length) {
            if (this.scannerTypeSelected === 'Camera') {
                this.action.pause();
            }
        }
    }

    onFileChange(event: any) {
        const file = event.target.files[0];
        this.subscription = this.qrReader.decode(file).subscribe((decodedString) => {
            log.debug('abc', decodedString);
            this.qrResultString = decodedString;
            this.onResult(this.qrResultString);
        });
    }
}
