import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { Logger } from './logger.service';

const log = new Logger('SingletonService');

@Injectable({
    providedIn: 'root'
})

export class SingletonService {

    public supportChains = [
        {
            networkId: 56, // **required in adding chain**
            networkName: 'Binance Smart Chain', // **required in adding chain**
            networkAbbr: 'bsc',
            rpcUrls: ['https://bsc-dataseed.binance.org/'], // **required in adding chain**
            nativeCurrency: { // **required in adding chain**
                name: 'BNB',
                symbol: 'BNB',
                decimals: 18,
            },
            blockExplorerUrls: ['https://bscscan.com/'], // **required in adding chain**
            imgUrl: '../../assets/img/chains/bsc.png',
            scanUrl: 'https://bscscan.com/',
            addressUrl: 'https://bscscan.com/address/',
            enabled: true,
            soon: false,
            use: 'rpc', // rpc or wss
            rpc: 'https://bsc-dataseed.binance.org',
        },
        {
            networkId: 96, // **required in adding chain**
            networkName: 'Bitkub Chain', // **required in adding chain**
            networkAbbr: 'bkc',
            rpcUrls: ['https://rpc.bitkubchain.io/'], // **required in adding chain**
            nativeCurrency: { // **required in adding chain**
                name: 'KUB COIN',
                symbol: 'KUB',
                decimals: 18,
            },
            blockExplorerUrls: ['https://bkcscan.com/'], // **required in adding chain**
            imgUrl: '../../assets/img/chains/bkc.png',
            scanUrl: 'https://bkcscan.com',
            addressUrl: 'https://bkcscan.com/address/',
            enabled: false,
            use: 'rpc', // rpc or wss
            rpc: 'https://rpc.bitkubchain.io',
            wss: 'wss://wss.bitkubchain.io',
        },
        {
            networkId: 25925, // **required in adding chain**
            networkName: 'Bitkub Chain - Testnet', // **required in adding chain**
            networkAbbr: 'bkctestnet',
            rpcUrls: ['https://rpc-testnet.bitkubchain.io'], // **required in adding chain**
            nativeCurrency: { // **required in adding chain**
                name: 'KUB COIN',
                symbol: 'KUB',
                decimals: 18,
            },
            blockExplorerUrls: ['https://testnet.bkcscan.com/'], // **required in adding chain**
            imgUrl: '../../assets/img/chains/bkc.png',
            scanUrl: 'https://testnet.bkcscan.com',
            addressUrl: 'https://testnet.bkcscan.com/address/',
            enabled: true,
            soon: false,
            use: 'rpc', // rpc or wss
            rpc: 'https://rpc-testnet.bitkubchain.io',
            wss: 'wss://wss-testnet.bitkubchain.io',
        },
        {
            networkId: 137, // **required in adding chain**
            networkName: 'Polygon', // **required in adding chain**
            networkAbbr: 'polygon',
            rpcUrls: ['https://polygon-rpc.com'], // **required in adding chain**
            nativeCurrency: { // **required in adding chain**
                name: 'MATIC',
                symbol: 'MATIC',
                decimals: 18,
            },
            blockExplorerUrls: ['https://polygonscan.com/'], // **required in adding chain**
            imgUrl: '../../assets/img/chains/polygon.png',
            scanUrl: 'https://polygonscan.com',
            addressUrl: 'https://polygonscan.com/address/',
            enabled: true,
            soon: true
        },
        {
            networkId: 80001, // **required in adding chain**
            networkName: 'Mumbai Polygon Testnet', // **required in adding chain**
            networkAbbr: 'polygontestnet',
            rpcUrls: ['https://rpc-mumbai.maticvigil.com'], // **required in adding chain**
            nativeCurrency: { // **required in adding chain**
                name: 'MATIC',
                symbol: 'MATIC',
                decimals: 18,
            },
            blockExplorerUrls: ['https://mumbai.polygonscan.com/'], // **required in adding chain**
            imgUrl: '../../assets/img/chains/polygon.png',
            scanUrl: 'https://mumbai.polygonscan.com',
            addressUrl: 'https://mumbai.polygonscan.com/address/',
            enabled: true,
            soon: false,
            use: 'rpc', // rpc or wss
            rpc: 'https://rpc-mumbai.maticvigil.com',
            wss: 'wss://rpc-mumbai.matic.today',
        },
    ];


    account: any;
    allowances: any;
    public accountSubject = new Subject<any>();
    private themeSubject = new Subject<any>();
    theme: string;

    provider: any;
    public publicProvider: any;

    public currentLanguage = new Subject<any>();

    public currentChain: Observable<any>;
    public currentChainSubject: BehaviorSubject<any>;

    public isWrongNetwork: Observable<boolean>;
    public isWrongNetworkSubject: BehaviorSubject<boolean>;

    constructor(
        private window: Window,
        private router: Router,
        private http: HttpClient,
        private translate: TranslateService,
    ) {
        this.currentChainSubject = new BehaviorSubject<any>(null);
        this.currentChain = this.currentChainSubject.asObservable();

        this.isWrongNetworkSubject = new BehaviorSubject<any>(undefined);
        this.isWrongNetwork = this.isWrongNetworkSubject.asObservable();
        this.theme = localStorage.getItem('theme');
    }

    getTheme() {
        let _theme = localStorage.getItem('theme');
        if (!_theme) {
            _theme = 'dark';
            localStorage.setItem('theme', _theme);
        }
        return _theme;
    }

    getWalletType() {
        return localStorage.getItem('walletType');
    }

    changeTheme(_theme: string) {
        localStorage.setItem('theme', _theme);
        this.themeSubject.next(_theme);
    }

    async updateConnectedWallet(walletAddress: any) {
        console.log('updateConnectedWallet walletAddress : %o', walletAddress);

        this.account = walletAddress;
        this.accountSubject.next(walletAddress);
        // await this.getCurrentChain()
    }

    getAccountSubject(): Observable<any> {
        return this.accountSubject.asObservable();
    }

    getThemeSubject(): Observable<any> {
        return this.themeSubject.asObservable();
    }

    getCurrentLang(): Observable<any> {
        return this.currentLanguage.asObservable();
    }

    async switchLanguage(lang: string) {
        if (localStorage.getItem('appLanguage')) {
            localStorage.removeItem('appLanguage');
            localStorage.setItem('appLanguage', lang);
        } else {
            localStorage.setItem('appLanguage', lang);
        }
        this.currentLanguage.next(lang);
    }

}
