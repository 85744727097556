import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './auth/helpers';

// module
import { ContentHeaderModule } from './layout/components/content-header/content-header.module';

// component
import { DocsComponent } from './main/views/docs/docs.component';
import { PrivacyComponent } from './main/views/privacy/privacy.component';
import { ScannerAirComponent } from './main/views/scan/scanner-air/scanner-air.component';
import { AuthLoginV2Component } from './main/pages/authentication/auth-login-v2/auth-login-v2.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full',
    },
    {
        path: 'dashboard',
        canActivate: [AuthGuard],
        data: {
            roles: ['ADMIN', 'ADMIN_READ_ONLY', 'MANAGER', 'MANAGER_READ_ONLY'],
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./main/views/dashboard/dashboard.module').then((m) => m.DashboardModule),
            },
            {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
        ],
    },
    {
        path: 'login',
        component: AuthLoginV2Component,
        children: [
            {
                path: '',
                loadChildren: () => import('./main/pages/authentication/authentication.module').then((m) => m.AuthenticationModule),
            },
            {path: '', redirectTo: 'login', pathMatch: 'full'},
        ],
    },
    {
        path: 'user',
        canActivate: [AuthGuard],
        data: {
            roles: ['ADMIN', 'ADMIN_READ_ONLY', 'MANAGER', 'MANAGER_READ_ONLY'],
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./main/views/user/user.module').then(m => m.UserModule)
            },
            {path: '', redirectTo: 'user', pathMatch: 'full'}
        ]
    },
    {
        path: 'assets',
        canActivate: [AuthGuard],
        data: {
            roles: ['ADMIN', 'ADMIN_READ_ONLY', 'MANAGER', 'MANAGER_READ_ONLY'],
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./main/views/assets/assets.module').then(m => m.AssetsModule)
            },
            {path: '', redirectTo: 'assets', pathMatch: 'full'}
        ]
    },
    {
        path: 'imports',
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                loadChildren: () => import('./main/views/imports/imports.module').then(m => m.ImportsModule)
            },
            {path: '', redirectTo: 'imports', pathMatch: 'full'}
        ]
    },
    {
        path: 'place',
        canActivate: [AuthGuard],
        data: {
            roles: ['ADMIN', 'ADMIN_READ_ONLY'],
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./main/views/place/place.module').then(m => m.PlaceModule)
            },
            {path: '', redirectTo: 'place', pathMatch: 'full'}
        ]
    },
    {

        path: 'building',
        canActivate: [AuthGuard],
        data: {
            roles: ['ADMIN', 'ADMIN_READ_ONLY'],
        },
        children: [
            {
                path: '',

                loadChildren: () => import('./main/views/building/building.module').then(m => m.BuildingModule)
            },
            {path: '', redirectTo: 'building', pathMatch: 'full'}
        ]
    },
    {
        path: 'floor',
        canActivate: [AuthGuard],
        data: {
            roles: ['ADMIN', 'ADMIN_READ_ONLY'],
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./main/views/floor/floor.module').then(m => m.FloorModule)
            },
            {path: '', redirectTo: 'floor', pathMatch: 'full'}
        ]
    },
    {
        path: 'location',
        canActivate: [AuthGuard],
        data: {
            roles: ['ADMIN', 'ADMIN_READ_ONLY'],
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./main/views/location/location.module').then(m => m.LocationModule)
            },
            {path: '', redirectTo: 'location', pathMatch: 'full'}
        ]
    },
    {
        path: 'target',
        canActivate: [AuthGuard],
        data: {
            roles: ['ADMIN', 'ADMIN_READ_ONLY'],
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./main/views/target/target.module').then(m => m.TargetModule)
            },
            {path: '', redirectTo: 'target', pathMatch: 'full'}
        ]
    },
    {
        path: 'maintenance',
        canActivate: [AuthGuard],
        data: {
            roles: ['ADMIN', 'ADMIN_READ_ONLY', 'MANAGER', 'MANAGER_READ_ONLY'],
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./main/views/maintenance/maintenance.module').then(m => m.MaintenanceModule)
            },
            {path: '', redirectTo: 'maintenance', pathMatch: 'full'}
        ]
    },
    {
        path: 'repair',
        canActivate: [AuthGuard],
        data: {
            roles: ['ADMIN', 'ADMIN_READ_ONLY', 'MANAGER', 'MANAGER_READ_ONLY'],
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./main/views/repair/repair.module').then(m => m.RepairModule)
            },
            {path: '', redirectTo: 'repair', pathMatch: 'full'}
        ]
    },
    {
        path: 'satisfaction-survey',
        canActivate: [AuthGuard],
        data: {
            roles: ['ADMIN', 'ADMIN_READ_ONLY', 'MANAGER', 'MANAGER_READ_ONLY'],
        },
        children: [
            {
                path: '',
                // tslint:disable-next-line:max-line-length
                loadChildren: () => import('./main/views/satisfaction-survey/satisfaction-survey.module').then(m => m.SatisfactionSurveyModule)
            },
            {path: '', redirectTo: 'satisfaction-survey', pathMatch: 'full'}
        ]
    },
    {
        path: 'maintenance-document',
        canActivate: [AuthGuard],
        data: {
            roles: ['ADMIN', 'ADMIN_READ_ONLY'],
        },
        children: [
            {
                path: '',
                // tslint:disable-next-line:max-line-length
                loadChildren: () => import('./main/views/maintenance-document/maintenance-document.module').then(m => m.MaintenanceDocumentModule)
            },
            {path: '', redirectTo: 'maintenance-document', pathMatch: 'full'}
        ]
    },
    {
        path: 'satisfaction-survey-document',
        canActivate: [AuthGuard],
        data: {
            roles: ['ADMIN', 'ADMIN_READ_ONLY', 'MANAGER', 'MANAGER_READ_ONLY'],
        },
        children: [
            {
                path: '',
                // tslint:disable-next-line:max-line-length
                loadChildren: () => import('./main/views/satisfaction-survey-document/satisfaction-survey-document.module').then(m => m.SatisfactionSurveyDocumentModule)
            },
            {path: '', redirectTo: 'satisfaction-survey-document', pathMatch: 'full'}
        ]
    },
    {
        path: 'map',
        canActivate: [AuthGuard],
        data: {
            roles: ['ADMIN', 'ADMIN_READ_ONLY'],
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./main/views/maps/maps.module').then(m => m.MapsModule)
            },
            {path: '', redirectTo: 'map', pathMatch: 'full'}
        ]
    },
    {
        path: 'check-in',
        canActivate: [AuthGuard],
        data: {
            roles: ['ADMIN', 'ADMIN_READ_ONLY', 'MANAGER', 'MANAGER_READ_ONLY'],
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./main/views/check-in/check-in.module').then(m => m.CheckInModule)
            },
            {path: '', redirectTo: 'check-in', pathMatch: 'full'}
        ]
    },
    {
        path: 'reports',
        canActivate: [AuthGuard],
        data: {
            roles: ['ADMIN', 'ADMIN_READ_ONLY', 'MANAGER', 'MANAGER_READ_ONLY'],
        },
        children: [
            {
                path: '',
                loadChildren: () => import('./main/views/reports/reports.module').then((m) => m.ReportsModule),
            },
            {path: '', redirectTo: 'reports', pathMatch: 'full'},
        ],
    },
    {
        path: 'scanner',
        canActivate: [AuthGuard],
        component: ScannerAirComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./main/views/scan/scanner-air/scanner-air.module').then(m => m.ScannerAirModule)
            },
            {path: '', redirectTo: 'scanner', pathMatch: 'full'}
        ]
    },
    {
        path: 'document',
        canActivate: [AuthGuard],
        component: DocsComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./main/views/docs/docs.module').then(m => m.DocsModule)
            },
            {path: '', redirectTo: 'document', pathMatch: 'full'}
        ]
    },
    {
        path: 'privacy',
        canActivate: [AuthGuard],
        component: PrivacyComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./main/views/privacy/privacy.module').then(m => m.PrivacyModule)
            },
            {path: '', redirectTo: 'privacy', pathMatch: 'full'}
        ]
    },
    {
        path: 'pages',
        loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
    },
    {
        path: '**',
        redirectTo: '/pages/miscellaneous/error' // Error 404 - Page not found
    }
];

@NgModule({
    imports: [
        ContentHeaderModule,
        RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})
    ],
    exports: [RouterModule]
})

export class AppRoutingModule {
}
