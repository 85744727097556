import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet-control-geocoder';

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
})

export class MapComponent implements AfterViewInit, OnChanges {

    @Output() locationSelected = new EventEmitter<{ lat: number | null, lng: number | null }>();

    @Input() defaultLatLng: { lat: number | null, lng: number | null };
    @Input() radius: number;

    pinHospital = L.icon({
        iconUrl: 'assets/icon/pins/pin.png',
        iconSize: [40, 40],
        shadowUrl: 'assets/icon/pins/pin-shadow.png',
        shadowSize: [40, 52],
        shadowAnchor: [12, 40]
    });

    // Public
    public latlng: { lat: number, lng: number };

    // Private
    private map;
    private currentMarker: L.Marker;
    private circle: L.Circle;

    constructor() {
    }

    ngAfterViewInit(): void {
        this.initMap();
        setTimeout(() => {
            this.map.invalidateSize();
        }, 100);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.defaultLatLng && this.map) {
            const {lat, lng} = this.defaultLatLng;
            if (lng && lng) {
                this.map.setView([lat, lng], 11);

                if (this.currentMarker) {
                    this.map.removeLayer(this.currentMarker);
                    this.map.removeLayer(this.circle);
                }

                this.currentMarker = L.marker([lat, lng])
                    .setIcon(this.pinHospital)
                    .addTo(this.map);

                this.circle = L.circle([lat, lng], {
                    color: '#EFB495',
                    fillColor: '#EFB495',
                    fillOpacity: 0.5,
                    radius: this.radius
                }).addTo(this.map);
            } else {
                if (this.currentMarker) {
                    this.map.removeLayer(this.currentMarker);
                    this.map.removeLayer(this.circle);
                }
            }
        }

        if (changes.radius && this.map) {
            this.circle.setRadius(this.radius);
        }
    }

    private initMap(): void {

        this.map = L.map('map').setView([13.736717, 100.524186], 11); // เริ่มต้นที่กรุงเทพฯ

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {maxZoom: 19}).addTo(this.map);

        // Add the geocoder control
        (L.Control as any).geocoder().addTo(this.map);

        const {lat, lng} = this.defaultLatLng;

        this.currentMarker = L.marker([lat, lng]).addTo(this.map);

        this.circle = L.circle([lat, lng], {
            color: '#EFB495',
            fillColor: '#EFB495',
            fillOpacity: 0.5,
            radius: this.radius
        }).addTo(this.map);

        // เพิ่มตัวดักฟังสำหรับเหตุการณ์การคลิก
        this.map.on('click', this.onMapClick.bind(this));
    }

    private onMapClick(event: L.LeafletMouseEvent) {
        const {lat, lng} = event.latlng;

        if (this.currentMarker) {
            this.map.removeLayer(this.currentMarker);
            this.map.removeLayer(this.circle);

        }
        // เพิ่มมาร์คเกอร์ที่จุดที่ผู้ใช้คลิก
        this.currentMarker = L.marker([lat, lng])
            .setIcon(this.pinHospital)
            .addTo(this.map);

        this.circle = L.circle(this.currentMarker.getLatLng(), {
            color: '#EFB495',
            fillColor: '#EFB495',
            fillOpacity: 0.5,
            radius: this.radius
        }).addTo(this.map);

        this.latlng = {
            lat: parseFloat(lat.toFixed(7)),
            lng: parseFloat(lng.toFixed(7))
        };
        this.locationSelected.emit(this.latlng);
    }
}
