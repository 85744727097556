import {CoreMenu} from '@core/types';

export const menu: CoreMenu[] = [
    {
        id: 'dashboard',
        title: 'แดชบอร์ด',
        translate: 'MENU.DASHBOARD',
        type: 'item',
        role: ['ADMIN', 'ADMIN_READ_ONLY', 'MANAGER', 'MANAGER_READ_ONLY'],
        icon: 'home',
        url: 'dashboard'
    },
    // User
    {
        id: 'user',
        title: 'ผู้ใช้งาน',
        translate: 'MENU.USER',
        type: 'item',
        role: ['ADMIN', 'MANAGER', 'MANAGER_READ_ONLY'],
        icon: 'user',
        url: 'user'
    },
    // Assets
    {
        id: 'assets',
        title: 'ทรัพย์สิน',
        translate: 'MENU.ASSETS',
        type: 'section',
        role: ['ADMIN', 'ADMIN_READ_ONLY', 'MANAGER', 'MANAGER_READ_ONLY'],
        icon: 'circle',
        children: [
            // Assets
            {
                id: 'assets',
                title: 'ทรัพย์สิน',
                translate: 'MENU.ASSETS',
                type: 'item',
                role: ['ADMIN', 'ADMIN_READ_ONLY', 'MANAGER', 'MANAGER_READ_ONLY'],
                icon: 'folder',
                url: 'assets'
            },
        ]
    },
    // Place, Building, Floor, Location, Map
    {
        id: 'place-location-map',
        title: 'สถานที่, อาคาร, ชั้น, ห้อง, แผนที่',
        translate: 'MENU.PLACE_LOCATION_MAP',
        type: 'section',
        role: ['ADMIN', 'ADMIN_READ_ONLY'],
        icon: 'circle',
        children: [
            // Place
            {
                id: 'place',
                title: 'สถานที่',
                translate: 'MENU.PLACE',
                type: 'item',
                role: ['ADMIN', 'ADMIN_READ_ONLY'],
                icon: 'home',
                url: 'place'
            },
            // Building
            {
                id: 'building',
                title: 'อาคาร',
                translate: 'MENU.BUILDING',
                type: 'item',
                role: ['ADMIN', 'ADMIN_READ_ONLY'],
                icon: 'triangle',
                url: 'building'
            },
            // Floor
            {
                id: 'floor',
                title: 'ชั้น',
                translate: 'MENU.FLOOR',
                type: 'item',
                role: ['ADMIN', 'ADMIN_READ_ONLY'],
                icon: 'square',
                url: 'floor'
            },
            // Location
            {
                id: 'location',
                title: 'ห้อง',
                translate: 'MENU.LOCATION',
                type: 'item',
                role: ['ADMIN', 'ADMIN_READ_ONLY'],
                icon: 'map-pin',
                url: 'location'
            },
            // Map
            {
                id: 'map',
                title: 'แผนที่',
                translate: 'MENU.MAPS',
                type: 'item',
                role: ['ADMIN', 'ADMIN_READ_ONLY'],
                icon: 'map',
                url: 'map'
            },
        ]
    },
    // Report
    {
        id: 'report',
        title: 'รายงาน',
        translate: 'MENU.REPORT',
        type: 'section',
        role: ['ADMIN', 'ADMIN_READ_ONLY', 'MANAGER', 'MANAGER_READ_ONLY'],
        icon: 'circle',
        children: [
            // Maintenance
            {
                id: 'maintenance',
                title: 'รายงานการบำรุงรักษา',
                translate: 'MENU.MAINTENANCE_REPORT',
                type: 'item',
                role: ['ADMIN', 'ADMIN_READ_ONLY', 'MANAGER', 'MANAGER_READ_ONLY'],
                icon: 'clipboard',
                url: 'maintenance'
            },
            // Repair
            {
                id: 'repair',
                title: 'รายงานการซ่อม',
                translate: 'MENU.REPAIR_REPORT',
                type: 'item',
                role: ['ADMIN', 'ADMIN_READ_ONLY', 'MANAGER', 'MANAGER_READ_ONLY'],
                icon: 'tool',
                url: 'repair'
            },
            // Satisfaction Survey
            {
                id: 'satisfaction-survey',
                title: 'รายงานแบบสำรวจความพึงพอใจ',
                translate: 'MENU.SATISFACTION_SURVEY_REPORT',
                type: 'item',
                role: ['ADMIN', 'ADMIN_READ_ONLY', 'MANAGER', 'MANAGER_READ_ONLY'],
                icon: 'file-text',
                url: 'satisfaction-survey'
            },
            // Templates
            {
                id: 'templates',
                title: 'เทมเพลต',
                translate: 'MENU.TEMPLATES',
                type: 'collapsible',
                role: ['ADMIN'],
                icon: 'archive',
                children: [
                    // Maintenance Document Templates
                    {
                        id: 'maintenance-document-templates',
                        title: 'เอกสารการบำรุงรักษา',
                        translate: 'MENU.MAINTENANCE_DOCUMENT',
                        type: 'item',
                        role: ['ADMIN'],
                        icon: 'circle',
                        url: 'maintenance-document'
                    },
                    // Satisfaction Survey Document Templates
                    {
                        id: 'satisfaction-survey-document-templates',
                        title: 'แบบสำรวจความพึงพอใจ',
                        translate: 'MENU.SATISFACTION_SURVEY_DOCUMENT',
                        type: 'item',
                        role: ['ADMIN'],
                        icon: 'circle',
                        url: 'satisfaction-survey-document'
                    },
                ]
            }
        ]
    },
    // Others
    {
        id: 'others',
        title: 'อื่นๆ',
        translate: 'MENU.OTHERS',
        type: 'section',
        role: ['ADMIN', 'ADMIN_READ_ONLY', 'MANAGER', 'MANAGER_READ_ONLY'],
        icon: 'circle',
        children: [
            // Target
            {
                id: 'target',
                title: 'เป้าหมายการดำเนินงาน',
                translate: 'MENU.TARGET',
                type: 'item',
                role: ['ADMIN'],
                icon: 'target',
                url: 'target'
            },
            // Check-In
            {
                id: 'check-in',
                title: 'เช็คอิน',
                translate: 'MENU.CHECK_IN',
                type: 'item',
                role: ['ADMIN', 'ADMIN_READ_ONLY', 'MANAGER', 'MANAGER_READ_ONLY'],
                icon: 'user-check',
                url: 'check-in'
            },
        ]
    }
];
