<div id="signature">
    <div class="con-canvas">
        <canvas #canvasSignature
                (touchstart)="startDrawing($event)"
                (touchmove)="moved($event)"
                width="700"
                height="200">
        </canvas>
    </div>
    <div class="text-center mt-2">
        <button type="button" class="btn btn-primary mr-1" (click)="savePad()">{{ 'TOOL.SAVE' | translate }}</button>
        <button type="button" class="btn btn-outline-danger " (click)="clearPad()">{{ 'TOOL.CLEAR' | translate }}</button>
    </div>
</div>
