import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

// module
import { AppComponent } from 'app/app.component';
import { AppRoutingModule } from './app-routing.module';
import { ComponentsModule } from './components/components.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreModule } from '@core/core.module';
import { LayoutModule } from 'app/layout/layout.module';

// ng ngx bootstrap
import { BlockUIModule } from 'ng-block-ui';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';

// component
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';

import 'hammerjs';
import { coreConfig } from 'app/app-config';
import { ErrorInterceptor, JwtInterceptor } from './auth/helpers';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,

        // module
        AppRoutingModule,
        ComponentsModule,
        CoreCommonModule,
        CoreModule.forRoot(coreConfig),
        LayoutModule,

        // ng ngx bootstrap
        BlockUIModule.forRoot(),
        ImageCropperModule,
        NgbModule,
        TranslateModule.forRoot(),
        ToastrModule.forRoot(),

        // Core modules
        CoreSidebarModule,
        CoreThemeCustomizerModule,
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        {provide: Window, useValue: window},
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
}

declare global {
    interface Window {
        web3: any;
        ethereum: any;
    }
}
