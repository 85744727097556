import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import SignaturePad from 'signature_pad';

// service
import { FilesService } from 'app/services/api/files.service';
import { Logger } from 'app/services/logger.service';

// service api
import { MaintenanceService } from '../../services/api/maintenance.service';

const log = new Logger('SignatureComponent');

@Component({
    selector: 'app-signature',
    templateUrl: './signature.component.html',
    styleUrls: ['./signature.component.scss'],
})

export class SignatureComponent implements OnInit, AfterViewInit {

    @ViewChild('canvasSignature') canvasEl!: ElementRef;
    @Input() signatureType!: 'ENGINEER' | 'AGENCY';

    signatureNeeded!: boolean;
    signaturePad!: SignaturePad;
    signatureImg!: string;

    constructor(
        private _filesService: FilesService,
        private _maintenanceService: MaintenanceService,
    ) {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.signaturePad = new SignaturePad(this.canvasEl.nativeElement, {
            penColor: '#03468F'
        });
    }

    startDrawing(event: Event) {
        // works in device not in browser
        // log.debug('startDrawing', event);
    }

    moved(event: Event) {
        // works in device not in browser
        // log.debug('moved', event);
    }

    clearPad() {
        this.signaturePad.clear();
    }

    savePad() {
        this.signatureImg = this.signaturePad.toDataURL();
        this.signatureNeeded = this.signaturePad.isEmpty();

        if (this.signatureImg) {

            const file = this._filesService.base64toFile(this.signatureImg, 'signature');

            const data = {
                signatureImage: file,
                signatureType: this.signatureType,
                status: 'save'
            };

            this._maintenanceService.maintenanceSignatureSubject.next(data);
        }
    }
}
