import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from 'app/auth/service';
import Swal from 'sweetalert2';

@Injectable({providedIn: 'root'})

export class AuthGuard implements CanActivate {

    /**
     *
     * @param {Router} _router
     * @param {AuthenticationService} _authenticationService
     */

    constructor(
        private _router: Router,
        private _authenticationService: AuthenticationService
    ) {
    }

    // canActivate
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this._authenticationService.currentUserValue;

        if (currentUser) {
            // console.debug('currentUser', currentUser.user?.role);
            // check if route is restricted by role
            if (route.data.roles && route.data.roles.indexOf(currentUser.user?.role) === -1) {
                // role not authorised so redirect to not-authorized page
                this._router.navigate(['/pages/miscellaneous/not-authorized']);
                return false;
            }

            const isTokenExpired = this.tokenExpired(currentUser?.tokens?.refreshToken);

            if (!isTokenExpired) {
                Swal.fire({
                    icon: 'error',
                    title: 'Token Expired!!',
                    text: 'Please Login Again',
                    footer: '<a href="/">Back to Home</a>'
                });
                this._router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
                return false;
            }


            // authorised so return true
            return true;
        } else {
            // not logged in so redirect to login page with the return url
            Swal.fire({
                icon: 'error',
                title: 'Need Authentication...',
                text: 'Alert From AuthGuard!',
                footer: '<a href="/">Back to Home</a>'
            }).then((res) => {
                    this._router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
                }
            );
            return false;
        }
    }

    // checkTokenExpired
    tokenExpired(refreshToken: string) {
        const expiry = (JSON.parse(atob(refreshToken.split('.')[1]))).exp;
        return (Math.floor((new Date).getTime() / 1000)) < expiry;
    }
}
