import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-scanner-air',
    templateUrl: './scanner-air.component.html',
    styleUrls: ['./scanner-air.component.scss']
})

export class ScannerAirComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }
}
