import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// module
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';

// ng-bootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// ng
import { NgSelectModule } from '@ng-select/ng-select';
import { ChartsModule } from 'ng2-charts';

// ngx
import { CsvModule } from '@ctrl/ngx-csv';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxGalleryModule } from 'ngx-gallery-9';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,

        // module
        ContentHeaderModule,

        // ng-bootstrap
        NgbModule,

        // ng
        NgSelectModule,
        ChartsModule,

        // ngx
        ImageCropperModule,
        NgxSpinnerModule,
        NgxGalleryModule,
        TranslateModule,
    ],
    exports: [
        CommonModule,

        // module
        ContentHeaderModule,

        // ng
        NgSelectModule,
        ChartsModule,

        // ngx
        CsvModule,
        ImageCropperModule,
        NgxSpinnerModule,
        NgxGalleryModule,
        TranslateModule,
    ]
})

export class SharedModule {
}
