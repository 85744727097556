import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { BrowserQRCodeReader } from '@zxing/browser';


@Injectable({
  providedIn: 'root'
})
export class ReaderService {

  constructor() { }

  decode(file: any): Observable<string> {
    return new Observable((observer: any) => {
      const codeReader = new BrowserQRCodeReader();
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e: any) => {
        codeReader.decodeFromImageUrl(reader.result.toString()).then((res) => {
          observer.next(res.getText());
          observer.complete();
        });
      };
    });
  }

}
