import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})
export class SweetAlertsService {

    public checkConfirm$ = new Subject<any>();

    constructor() {
    }

    async confirmTextOpen({title = 'Are you sure?', text = '', confirmButtonText = 'Yes, Confirm it!'}): Promise<any> {
        return await Swal.fire({
            title,
            text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#7367F0',
            cancelButtonColor: '#E42728',
            confirmButtonText,
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-danger ml-1'
            }
        }).then(function (result) {
            if (result.value) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Your data has been Confirm.',
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                });
                return true;
            }
            return false;
        }).catch((e) => {
            return false;
        });
    }

    async confirmText({title = 'Are you sure?', text = '', confirmButtonText = 'Yes, Confirm it!'}): Promise<any> {
        return await Swal.fire({
            title,
            text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#7367F0',
            cancelButtonColor: '#E42728',
            confirmButtonText,
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-danger ml-1'
            }
        }).then(function (result) {

            if (result.value) {
                return true;
            }
            return false;
        }).catch((e) => {
            return false;
        });
    }

    okGet(title = 'ไม่มีรายการ') {
        Swal.fire({
            icon: 'warning',
            title,
            text: 'Your file has been do it.',
            customClass: {
                confirmButton: 'btn btn-danger'
            }
        });
    }

    saveDataSuccess(title = 'Success') {
        Swal.fire({
            icon: 'success',
            title,
            // text: 'Data is saved',
            showConfirmButton: false,
            timer: 2000,
        });
    }

    showSuccess(title = 'Success') {
        Swal.fire({
            icon: 'success',
            title,
            text: 'Already been sent',
            customClass: {
                confirmButton: 'btn btn-success'
            }
        });
    }

    async showSuccessAwait(title = 'Success'): Promise<any> {
        return await Swal.fire({
            icon: 'success',
            title,
            text: 'Already been sent',
            customClass: {
                confirmButton: 'btn btn-success'
            }
        }).then(r => r.value);
    }

    async alertError({title = 'Error', text = 'โปรดตรวจสอบความถูกต้องอีกครั้ง'}) {
        return await Swal.fire({
            icon: 'warning',
            title,
            text,
            customClass: {
                confirmButton: 'btn btn-danger'
            }
        }).then(r => r.value);
    }
}
