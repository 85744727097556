export const locale = {
    lang: 'en',
    data: {
        MENU: {
            HOME: 'Home',
            DASHBOARD: 'Dashboard',
            DOCUMENT: 'Document',
            SAMPLE: 'Sample',
            USER: 'User',
            ASSETS: 'Assets',
            IMPORT_ASSETS: 'Import Asset Data',
            ASSET_INFO: 'Asset-Info',
            PLACE_LOCATION_MAP: 'Place, Location, Map',
            PLACE: 'Place',
            BUILDING: 'Building',
            FLOOR: 'Floor',
            LOCATION: 'Location',
            MAPS: 'Map',
            MAINTENANCE: 'Maintenance',
            MAINTENANCE_REPORT: 'Maintenance Report',
            REPAIR: 'Repair',
            REPAIR_REPORT: 'Repair Report',
            TEMPLATES: 'Templates',
            MAINTENANCE_DOCUMENT: 'Maintenance Document',
            MAINTENANCE_DOCUMENT_TEMPLATES: 'Maintenance Document Templates',
            MAINTENANCE_DOCUMENT_QUESTIONS: 'Maintenance Document Questions',
            MAINTENANCE_DOCUMENT_CHOICES: 'Maintenance Document Choices',
            SATISFACTION_SURVEY_DOCUMENT: 'Satisfaction Survey Document',
            SATISFACTION_SURVEY_DOCUMENT_TEMPLATES: 'Satisfaction Survey Document Templates',
            SATISFACTION_SURVEY_DOCUMENT_QUESTIONS: 'Satisfaction Survey Document Questions',
            SATISFACTION_SURVEY_DOCUMENT_CHOICES: 'Satisfaction Survey Document Choices',
            TARGET: 'Target',
            CHECK_IN: 'Check-in',
            REPORT: 'Report',
            SCANNER: 'Scanner',
            OTHERS: 'Others'
        },
        // Dashboard
        DASHBOARD: {
            MONTH: 'Month',
            YEAR: 'Year',
        },
        // User
        USER: {},
        // Assets
        ASSETS: {},
        // Asset-Info
        ASSET_INFO: {},
        // Place
        PLACE: {},
        // Building
        BUILDING: {},
        // Floor
        FLOOR: {},
        // Location
        LOCATION: {},
        // Map
        MAPS: {},
        // Maintenance
        MAINTENANCE: {},
        // Repair
        REPAIR: {},
        // Target
        TARGET: {},
        // Check-in
        CHECK_IN: {},
        // Report
        REPORT: {},
        // Others
        OTHERS: {},
        SELECT_FILTER: {
            NEWEST: 'newest',
            OLDEST: 'oldest',
        },
        TOOL: {
            ADD: 'Add',
            EDIT: 'Edit',
            DELETE: 'Delete',
            ACTION: 'Action',
            SUBMIT: 'Submit',
            SAVE: 'Save',
            OPEN: 'Open',
            CLOSE: 'Close',
            CLEAR: 'Clear',
            BACK: 'Back',
            UPLOAD_FILE: 'Upload File',
            DOWNLOAD_FILE: 'Download File',
            SHOW: 'Show',
            TOTAL: 'Total',

            START_DATE: 'Start Date',
            END_DATE: 'End Date',

            // Swal
            YES: 'Yes',
            NO: 'No',
            ARE_YOU_SURE: 'Are you sure ?',
            YES_CONFIRM: 'Yes, Confirm it',
            CONFIRM: 'Confirm',
            CANCEL: 'Cancel',
            TITLE_CONFIRM: 'Confirm!',
            TEXT_CONFIRM: 'Your data has been Confirm.',
        },
        VALIDATOR: {
            REQUIRE: 'This field is required',
            CORRECT: 'This field is entered correctly'
        },
        TOAST: {
            ERROR: 'error',
            SUCCESS: 'success',
            CHECK_YOUR_INPUT_AGAIN: 'Check your input again',
            BLOG_CREATED: 'Blog created successfully',
            BLOG_EDITED: 'Blog edit successfully',
            BLOG_ENABLE: 'The blog has been enabled',
            BLOG_DISABLE: 'The blog has been disabled',
            PROJECT_CREATED: 'Created successfully',
            PROJECT_UPDATED: 'Updated successfully',
            ADD_CONTRACT: 'Add contract successfully',
            UPDATE_CONTRACT: 'Update contract successfully',
            ADD_AUDIT_FILE: 'Add audit file successfully',
            ADD_AUDIT_PDF_FILE: 'Add audit pdf file successfully',
            ADD_FINDING: 'Add finding successfully',
            UPDATE_AUDIT_FILE: 'Update audit file successfully',
            UPDATE_FINDING: 'Update finding successfully',
            REQUEST_QUOTE_SEND: 'Request Quote has been send',
            SIGNED_IN_SUCCESS: 'Signed in successfully',
            UPDATED: 'Update Successfully'
        },
        MONTH: {
            JANUARY: 'January',
            FEBRUARY: 'February',
            MARCH: 'March',
            APRIL: 'April',
            MAY: 'May',
            JUNE: 'June',
            JULY: 'July',
            AUGUST: 'August',
            SEPTEMBER: 'September',
            OCTOBER: 'October',
            NOVEMBER: 'November',
            DECEMBER: 'December',
        },
    }
};
