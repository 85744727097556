import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable ,throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { AuthenticationService } from 'app/auth/service';
import { TranslateService } from '@ngx-translate/core';

import { Logger } from 'app/services/logger.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

const log = new Logger('JwtInterceptor');
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  /**
   *
   * @param {AuthenticationService} _authenticationService
   */
  constructor(
    private _authenticationService: AuthenticationService,
    private _translateService: TranslateService,
    private _router: Router,) { }

  /**
   * Add auth header with jwt if user is logged in and request is to api url
   * @param request
   * @param next
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const currentUser = this._authenticationService.currentUserValue;

    const isLoggedIn = currentUser && currentUser?.tokens?.accessToken;
    // const isApiUrl = request.url.startsWith(environment.apiUrl);
    if (isLoggedIn) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.tokens.accessToken}`
        }
      });
    }
    // return next.handle(request);

    return next.handle(request).pipe(


      catchError(err => {
        log.debug('err jwt ---', err);
        // log.debug('err jwt ---', err == 'Please authenticate');
        if (err === 'Please authenticate') {
          log.debug('check jwt 401', err.status)
          // let msgTitle = this._translateService.instant('SWALFIRE.OOPS')
          // let msgHTML = this._translateService.instant('SWALFIRE.YOUR_SESSION_EXPIRED')
          let msgTitle = 'OOPS';
          let msgHTML = 'Your session expired'

          // auto logout if 401 response returned from api
          Swal.fire({
            title: '<strong style="color:#f27474">' + msgTitle + '</strong>',
            html: '<h1 style="color:#1A1818">' + msgHTML + '</h1>',
            // text: 'Your session has expired. Please login again.',
            icon: 'error',
            confirmButtonText: 'OK',
            showConfirmButton: true
          }).then(() => {
            log.debug('err jwt then 123456', err);
            this._authenticationService.logout();

            this._router.navigate(['login']);
          });
        }

        if (err.status == 400) {
          log.debug('check jwt', err.status)
          let msgTitle = this._translateService.instant('SWALFIRE.OOPS')
          let msgHTML = 'Something wrong!'

          Swal.fire({
            title: '<strong style="color:#f27474">' + msgTitle + '</strong>',
            html: '<h1 style="color:#1A1818">' + msgHTML + '</h1>',
            // text: 'Your session has expired. Please login again.',
            icon: 'error',
            confirmButtonText: 'OK',
            showConfirmButton: true
          })
        }


        return throwError(err);
      })
    );
  }
}


