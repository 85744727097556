<div class="container" [ngClass]="imageChangedEvent && croppedImage ? 'con-have-item' : 'con-no-item'">
    <div class="con-form">
        <div class="con-input">
            <label for="inputPlan" class="add-image" [hidden]="imageChangedEvent && croppedImage">
                <div class="image">
                    <img alt="amulet" src="/assets/image/resize-image.png"/>
                </div>
                <div class="image-text">
                    {{ croppedImage ? "เปลี่ยนแปลน" : "เพิ่มแปลน" }}
                </div>
            </label>
            <input id="inputPlan"
                   #inputPlan
                   type="file"
                   accept="image/*"
                   style="display: none"
                   (change)="fileChangeEvent($event)"/>
        </div>
    </div>

    <div class="con-f" *ngIf="imageChangedEvent && croppedImage">

        <div class="none"></div>

        <div class="image-size">
            <div class="crop-width">
                ขนาด width: <span>{{ croppedImageWidth }}</span>
            </div>
            <div class="crop-height">
                ขนาด height: <span>{{ croppedImageHeight }}</span>
            </div>
        </div>

        <div class="con-btn">
            <button type="button" class="btn btn-primary mr-1" (click)="confirm()">
                ครอบรูป
            </button>
            <button type="button" class="btn border btn-1" (click)="openFileInput()">
                เลือกแปลนใหม่
            </button>
        </div>
    </div>

    <div class="cropper-container">
        <image-cropper
                [imageChangedEvent]="imageChangedEvent"
                [maintainAspectRatio]="false"
                [aspectRatio]="1.414 / 1"
                format="png"
                (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded()"
                (cropperReady)="cropperReady()"
                (loadImageFailed)="loadImageFailed()">
        </image-cropper>
    </div>
</div>
