import { Component, Input } from '@angular/core';

@Component({
    selector: 'asset-pin',
    templateUrl: './asset-pin.component.html',
    styleUrls: ['./asset-pin.component.scss']
})

export class AssetPinComponent {

    @Input() disable: boolean;
    @Input() ripple = false;

    @Input() text: string;
    @Input() color = 'red';
}
