<!-- Menu header -->
<div class="navbar-header">
    <ul class="nav navbar-nav flex-row navbar-icon-text_logo">
        <li class="nav-item mr-auto">
            <!-- App Branding -->
            <a class="navbar-brand" [routerLink]="['/dashboard']">
                <span class="brand-logo">
                    <img src="{{coreConfig.app.appLogoImage}}" alt="brand-logo"/>
                </span>
                <h2 class="brand-text mb-0">{{coreConfig.app.appName}}</h2>
            </a>
            <!--/ App Branding -->
        </li>

        <!-- Menu Toggler -->
        <li class="nav-item nav-toggle">
            <a class="nav-link modern-nav-toggle d-none d-xl-block pr-0" (click)="toggleSidebarCollapsible()">
                <i [ngClass]="isCollapsed === true ? 'icon-circle' : 'icon-disc'"
                   class="toggle-icon feather font-medium-4 collapse-toggle-icon text-primary">
                </i>
            </a>
            <a class="nav-link modern-nav-toggle d-block d-xl-none pr-0" (click)="toggleSidebar()">
                <i data-feather="x" class="font-medium-4 text-primary toggle-icon"></i>
            </a>
        </li>
        <!--/ Menu Toggler -->
    </ul>
</div>
<!--/ Menu header -->

<header class="profile-header">
    <!-- User Information -->
    <div class="profile">
        <div class="avatar box-shadow-1 avatar-xl avatar-border" (click)="editProfile()">
            <img [src]="currentUser?.user?.picture ? currentUser?.user?.picture : 'assets/images/portrait/small/user.png'" alt="" />
        </div>
        <h4 class="user-name">{{currentUser?.user?.firstname && currentUser?.user?.lastname ? currentUser?.user?.firstname + ' ' + currentUser?.user?.lastname : '-'}}</h4>
        <span class="user-role">
            {{ currentUser?.user?.role === 'ADMIN' ?  'ADMIN' :
            currentUser?.user?.role === 'ADMIN_READ_ONLY' ? 'ADMIN READ ONLY' :
                currentUser?.user?.role === 'MANAGER' ? 'MANAGER' :
                    currentUser?.user?.role === 'MANAGER_READ_ONLY' ? 'MANAGER READ ONLY' :
                        currentUser?.user?.role === 'TECHNICIAN' ? 'TECHNICIAN' :
                            currentUser?.user?.role === 'USER' ? 'USER' : '' }}
        </span>
        <div class="user-place" *ngIf="currentUser?.user?.role === 'MANAGER' || currentUser?.user?.role === 'MANAGER_READ_ONLY'">
            <div class="text_user__place">
                <ng-container *ngFor="let userPlace of currentUser.user.userPlace; let i = index">
                    <div *ngIf="i < 3">
                        {{ userPlace.place.name }}
                    </div>
                    <div *ngIf="i === 3 && currentUser.user.userPlace.length > 3" placement="auto">
                        <span [ngbTooltip]="tooltipContent" style="cursor: pointer">. . .</span>
                    </div>
                </ng-container>
            </div>

            <ng-template #tooltipContent>
                <ul>
                    <li *ngFor="let userPlace of currentUser.user.userPlace; let i = index" [ngStyle]="{ 'margin-top.px': i >= 3 ? 5 : 0 }">
                        {{ userPlace.place.name }}
                    </li>
                </ul>
            </ng-template>
            <div class="icon-no-place" *ngIf="isCollapsed">
                <i data-feather="more-horizontal"></i>
            </div>
        </div>
    </div>
    <!--/ User Information -->
</header>

<!-- Navbar shadow -->
<div class="shadow-bottom" [ngClass]="{ 'd-block': isScrolled }"></div>

<!-- Main menu -->
<div class="main-menu-content main-menu-content-custom" [perfectScrollbar] (scroll)="onSidebarScroll()">
    <ul class="navigation navigation-main" layout="vertical" core-menu></ul>
    <div class="account-list-wrapper mt-auto bg-light-dark border-top">
        <div class="account-list-inner">
            <div class="account-list-select">
                <div ngbDropdown class="btn-select-account-wrapper d-inline-block w-100 shadow">
                    <button type="button" class="btn-select-account btn border w-100" id="dropdown-account-list" ngbDropdownToggle>
                        <i data-feather="align-justify" class="mr-1"></i>
                        <span class="btn-more">More</span>
                    </button>
                    <ul class="sub-dropdown-account-list" ngbDropdownMenu aria-labelledby="dropdown-account-list">
                        <li class="sub-dropdown-account-item px-2 py-50" placement="auto" ngbTooltip="แก้ไขโปรไฟล์">
                            <a class="d-block w-100" (click)="editProfile()">
                                <i data-feather="settings" class="mr-1"></i>
                                <span class="edit-profile">Edit profile</span>
                            </a>
                        </li>
                        <li class="sub-dropdown-account-item px-2 py-50" placement="auto" ngbTooltip="ช่วยเหลือ">
                            <a class="d-block w-100">
                                <i data-feather="help-circle" class="mr-1"></i>
                                <span class="help">Help</span>
                            </a>
                        </li>
                        <li class="sub-dropdown-account-item px-2 py-50" placement="auto" ngbTooltip="ออกจากระบบ">
                            <a class="d-block w-100" (click)="logout()">
                                <i data-feather="log-out" class="mr-1"></i>
                                <span class="log-out">Log out</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!--/ Main menu -->
