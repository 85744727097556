<ng-template #modalSignature let-modal>
    <div class="modal-header">
        <h3 class="modal-title">เซ็นชื่อรับรอง</h3>
        <button type="button" class="close" (click)="modal.close('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="card-body">
            <div class="form-group" *ngIf="type === 'CLEAN'">
                <label class="form-label" for="position">ตำแหน่ง</label>
                <select class="form-control"
                        id="position"
                        [(ngModel)]="signatureType"
                        (change)="choiceSignatureType($event)">
                    <option hidden value="" disabled selected>เลือก "ตำแหน่ง"</option>
                    <option [value]="option.value" *ngFor="let option of optionPosition">{{ option.name }}</option>
                </select>
            </div>
            <div class="signature">
               <app-signature [signatureType]="signatureType"></app-signature>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="form-group mb-1">
            <button type="button" class="btn border" (click)="modal.close('Cross click')" rippleEffect>
                {{ 'TOOL.CLOSE' | translate }}
            </button>
        </div>
    </div>
</ng-template>
