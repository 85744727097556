<!--<div class="footer pt-3 pb-2">-->
<!--  <div class="container">-->

<!--    <div class="row">-->
<!--      <div class="col-md-6 ">-->
<!--        <a href="#" >-->
<!--          <h1 class="mb-4">-->
<!--            TW-MSS DASHBOARD-->
<!--          </h1>-->
<!--          &lt;!&ndash; <img src="./../../../../assets/images/logo/logo-with-text-white.png" alt="DATA ORACLE LOGO" height="35"> &ndash;&gt;-->
<!--        </a>-->
<!--        <div class="desc-footer mb-2 mt-2 pr-5">-->
<!--          &lt;!&ndash; BKCPORT is a tool for inspecting and analyzing EVM based blockchains. Blockchain explorer for Bitkub Chain Networks. &ndash;&gt;-->
<!--          <p class="text-muted">decentralized oracle networks provide tamper-proof inputs, outputs, and computations to support advanced smart contracts on any blockchain.</p>-->
<!--        </div>-->
<!--        <div class="social-footer">-->
<!--          <a href="#" class="mr-50">-->
<!--            <i class="ficon font-medium-2 feather icon-twitter text-white"></i>-->
<!--          </a>-->
<!--          <a href="#" class="mr-50">-->
<!--            <i class="ficon font-medium-2 feather icon-send text-white"></i>-->
<!--          </a>-->
<!--          <a href="#" class="mr-50">-->
<!--            <i class="ficon font-medium-2 feather icon-github text-white"></i>-->
<!--          </a>-->
<!--          <a href="#" class="mr-50">-->
<!--            <i class="ficon font-medium-2 feather icon-youtube text-white"></i>-->
<!--          </a>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="col-md-3">-->
<!--        <div class="related-links">-->
<!--          <h6 class="font-weight-normal mb-2 text-muted">Developers</h6>-->
<!--          <ul class="pl-0">-->
<!--            <li><a href="#" rel="noreferrer" target="_blank" class="footer-link">Docs</a></li>-->
<!--            <li><a href="#" rel="noreferrer" target="_blank" class="footer-link">Developer resources</a></li>-->
<!--            <li><a href="#" rel="noreferrer" target="_blank" class="footer-link">Developer experts</a></li>-->
<!--            <li><a href="#" rel="noreferrer" target="_blank" class="footer-link">Using Data Feeds</a></li>-->
<!--            <li><a href="#" rel="noreferrer" target="_blank" class="footer-link">Using Any API</a></li>-->
<!--            </ul>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="col-md-3">-->
<!--        <div class="documentation">-->
<!--          <h6 class="font-weight-normal mb-2 text-muted">Products</h6>-->
<!--          <ul class="pl-0">-->
<!--            <li><a href="#" rel="noreferrer" target="_blank" class="footer-link">Market and Data Feeds</a></li>-->
<!--            <li><a href="#" rel="noreferrer" target="_blank" class="footer-link">VRF</a></li>-->
<!--            <li><a href="#" rel="noreferrer" target="_blank" class="footer-link">Cross-Chain Bridging</a></li>-->
<!--          </ul>-->
<!--        </div>-->
<!--      </div>-->

<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<!-- Footer -->
<!--<div class="footer-copyright">-->
<!--  <div class="container">-->
<!--    <p class="clearfix mb-0">-->
<!--      <span class="text-center d-block text-white mt-25">-->
<!--        COPYRIGHT &copy; {{ year }}-->
<!--        <a class="ml-25 text-white border-bottom" href="#" target="_blank">-->
<!--          TW-MSS DASHBOARD-->
<!--        </a>-->
<!--        <span class="d-none d-sm-inline-block text-white">, All rights Reserved</span>-->
<!--      </span>-->
<!--    </p>-->
<!--  </div>-->
<!--</div>-->

<p class="clearfix mb-0">
  <span class="float-md-left d-block d-md-inline-block mt-25">
    COPYRIGHT &copy; {{ year }}
    <a class="ml-25" href="#" target="_blank">TW-MSS DASHBOARD</a>
    <span class="d-none d-sm-inline-block">, All rights Reserved</span>
  </span>
  <span class="float-md-right d-none d-md-block">
    Hand-crafted & Made with <span [data-feather]="'heart'" [class]="'pink'"></span>
  </span>
</p>
<!--/ Footer -->


<!-- Move to top Button-->
<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>
