<ng-container>
    <div class="form-group">
        <select id="camera-select"
                (change)="onDeviceSelectChange($event)"
                class="form-control">
            <option value="" [selected]="!currentDevice">No Device Selected</option>
            <option *ngFor="let device of availableDevices" [value]="device.deviceId" [selected]="currentDevice && device.deviceId === currentDevice.deviceId">
                {{ device.label }}
            </option>
        </select>
    </div>

    <div id="qr-scanner-with-camera">
        <zxing-scanner
                [(device)]="currentDevice"
                (scanSuccess)="onCodeResult($event)"
                [formats]="formatsEnabled"
                [tryHarder]="tryHarder"
                (permissionResponse)="onHasPermission($event)"
                (camerasFound)="onCamerasFound($event)">
        </zxing-scanner>
    </div>
    <div class="pb-3">
        <label for="file-input" class="btn btn-outline-secondary btn-block mt-2">Or upload QR Code image</label>
        <input type="file"
               id="file-input"
               accept="image/*"
               (change)="onFileChange($event)"/>
    </div>
</ng-container>
