import { Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';

// ng ngx bootstrap
import { TranslateService } from '@ngx-translate/core';

// service
import { Logger } from 'app/services/logger.service';
import { ModalService } from 'app/services/modal.service';
import { SharedModalService } from 'app/shared/services/shared-modal.service';

const log = new Logger('SignatureModalComponent');

@Component({
    selector: 'app-signature-modal',
    templateUrl: './signature-modal.component.html',
    styleUrls: ['./signature-modal.component.scss'],
})

export class SignatureModalComponent implements OnInit {

    @ViewChild('modalSignature', {static: false}) modalSignatureTemplate!: TemplateRef<any>;

    // Public
    public type = 'CLEAN';

    public optionPosition = [
        {name: 'วิศวะกรรม', value: 'ENGINEER'},
        {name: 'หน่วยงาน', value: 'AGENCY'},
    ];

    public signatureType: any | 'ENGINEER';

    constructor(
        private _translateService: TranslateService,
        private _modalService: ModalService,
        private _sharedModalService: SharedModalService,
    ) {
        this._modalService.modalSignatureData$.subscribe((data) => {
            if (data) {
                this.type = data.type;
                this.signatureType = data.signatureType;
            }
        });
    }

    ngOnInit() {
        setTimeout(() => {
            this._sharedModalService.modalSignatureTemplate = this.modalSignatureTemplate;
        }, 0);
    }

    choiceSignatureType(event: any) {
        this.signatureType = event.target.value;
    }
}
