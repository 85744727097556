import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class SharedModalService {

  private _sharedModalTemplate!: TemplateRef<any>;
  private _sharedModalSignatureTemplate!: TemplateRef<any>;

  set modalTemplate(template: TemplateRef<any>) {
    this._sharedModalTemplate = template;
  }

  get modalTemplate(): TemplateRef<any> {
    return this._sharedModalTemplate;
  }

  set modalSignatureTemplate(template: TemplateRef<any>) {
    this._sharedModalSignatureTemplate = template;
  }

  get modalSignatureTemplate(): TemplateRef<any> {
    return this._sharedModalSignatureTemplate;
  }
}
