export const locale = {
    lang: 'th',
    data: {
        MENU: {
            HOME: 'หน้าแรก',
            DASHBOARD: 'แดชบอร์ด',
            DOCUMENT: 'คู่มือการใช้งาน',
            SAMPLE: 'ตัวอย่าง',
            USER: 'ผู้ใช้',
            ASSETS: 'ทรัพย์สิน',
            IMPORT_ASSETS: 'อัปโหลดไฟล์ ทรัพย์สิน',
            ASSET_INFO: 'ข้อมูลทรัพย์สิน',
            PLACE_LOCATION_MAP: 'สถานที่, ห้อง, แผนที่',
            PLACE: 'สถานที่',
            BUILDING: 'อาคาร',
            FLOOR: 'ชั้น',
            LOCATION: 'ห้อง',
            MAPS: 'แผนที่',
            MAINTENANCE: 'การบำรุงรักษา',
            MAINTENANCE_REPORT: 'รายงานการบำรุงรักษา',
            REPAIR: 'การซ่อม',
            REPAIR_REPORT: 'รายงานการซ่อม',
            SATISFACTION_SURVEY: 'แบบสำรวจความพึงพอใจ',
            SATISFACTION_SURVEY_REPORT: 'รายงานแบบสำรวจความพึงพอใจ',
            TEMPLATES: 'เทมเพลต',
            MAINTENANCE_DOCUMENT: 'เอกสารการบำรุงรักษา',
            MAINTENANCE_DOCUMENT_TEMPLATES: 'เทมเพลตเอกสารการบำรุงรักษา',
            MAINTENANCE_DOCUMENT_QUESTIONS: 'คำถามเอกสารการบำรุงรักษา',
            MAINTENANCE_DOCUMENT_CHOICES: 'ตัวเลือกเอกสารการบำรุงรักษา',
            SATISFACTION_SURVEY_DOCUMENT: 'เอกสารแบบสำรวจความพึงพอใจ',
            SATISFACTION_SURVEY_DOCUMENT_TEMPLATES: 'เทมเพลตเอกสารแบบสำรวจความพึงพอใจ',
            SATISFACTION_SURVEY_DOCUMENT_QUESTIONS: 'คำถามเอกสารแบบสำรวจความพึงพอใจ',
            SATISFACTION_SURVEY_DOCUMENT_CHOICES: 'ตัวเลือกเอกสารแบบสำรวจความพึงพอใจ',
            TARGET: 'เป้าหมาย',
            CHECK_IN: 'เช็คอิน',
            REPORT: 'รายงาน',
            SCANNER: 'เครื่องสแกน',
            OTHERS: 'อื่นๆ'
        },
        // Dashboard
        DASHBOARD: {
            MONTH: 'เดือน',
            YEAR: 'ปี',
        },
        // User
        USER: {},
        // Assets
        ASSETS: {},
        // Asset-Info
        ASSET_INFO: {},
        // Place
        PLACE: {},
        // Building
        BUILDING: {},
        // Floor
        FLOOR: {},
        // Location
        LOCATION: {},
        // Map
        MAPS: {},
        // Maintenance
        MAINTENANCE: {},
        // Repair
        REPAIR: {},
        // Target
        TARGET: {},
        // Check-in
        CHECK_IN: {},
        // Report
        REPORT: {},
        // Others
        OTHERS: {},
        SELECT_FILTER: {
            NEWEST: 'ใหม่สุด',
            OLDEST: 'เก่าสุด',
        },
        TOOL: {
            ADD: 'เพิ่ม',
            EDIT: 'แก้ไข',
            DELETE: 'ลบ',
            ACTION: 'การจัดการ',
            SUBMIT: 'ส่ง',
            SAVE: 'บันทึก',
            OPEN: 'เปิด',
            CLOSE: 'ปิด',
            CLEAR: 'ล้าง',
            BACK: 'กลับ',
            UPLOAD_FILE: 'อัพโหลดไฟล์',
            DOWNLOAD_FILE: 'ดาวน์โหลด แบบฟอร์ม',
            SHOW: 'แสดง',
            TOTAL: 'รวม',

            START_DATE: 'วันที่เริ่มต้น',
            END_DATE: 'วันที่สิ้นสุด',

            // Swal
            YES: 'ใช่',
            NO: 'ไม่ใช่',
            ARE_YOU_SURE: 'คุณแน่ใจไหม ?',
            YES_CONFIRM: 'ใช่, ยืนยัน',
            CONFIRM: 'ยืนยัน',
            CANCEL: 'ยกเลิก',
            TITLE_CONFIRM: 'ยืนยัน!',
            TEXT_CONFIRM: 'ข้อมูลของคุณได้รับการยืนยันแล้ว.',
        },
        VALIDATOR: {
            REQUIRE: 'จำเป็นต้องมีข้อมูลในช่องนี้',
            CORRECT: 'กรอกข้อมูลเรียบร้อย'
        },
        TOAST: {
            ERROR: 'ผิดพลาด',
            SUCCESS: 'สำเร็จ',
            CHECK_YOUR_INPUT_AGAIN: 'กรุณาตรวจสอบข้อมูลอีกครั้ง',
            BLOG_CREATED: 'สร้างบทความสำเร็จ',
            BLOG_EDITED: 'แก้ไขบทความสำเร็จ',
            BLOG_ENABLE: 'เปิดใช้งานบทความสำเร็จ',
            BLOG_DISABLE: 'ปิดใช้งานบทความสำเร็จ',
            PROJECT_CREATED: 'สร้างโปรเจ็คสำเร็จ',
            PROJECT_UPDATED: 'แก้ไขโปรเจ็คสำเร็จ',
            ADD_CONTRACT: 'เพิ่มสัญญาสำเร็จ',
            UPDATE_CONTRACT: 'แก้ไขสัญญาสำเร็จ',
            ADD_AUDIT_FILE: 'เพิ่มไฟล์การตรวจสอบสำเร็จ',
            ADD_AUDIT_PDF_FILE: 'เพิ่มไฟล์สรุปการตรวจสอบสำเร็จ',
            ADD_FINDING: 'เพิ่มผลตรวจสำเร็จ',
            UPDATE_AUDIT_FILE: 'แก้ไขไฟล์การตรวจสอบสำเร็จ',
            UPDATE_FINDING: 'แก้ไขผลตรวจสำเร็จ',
            REQUEST_QUOTE_SEND: 'ส่งคำขอสำเร็จ',
            SIGNED_IN_SUCCESS: 'เข้าสู่ระบบสำเร็จ',
            UPDATED: 'อัพเดทสำเร็จ'
        },
        MONTH: {
            JANUARY: 'มกราคม',
            FEBRUARY: 'กุมภาพันธ์',
            MARCH: 'มีนาคม',
            APRIL: 'เมษายน',
            MAY: 'พฤษภาคม',
            JUNE: 'มิถุนายน',
            JULY: 'กรกฏาคม',
            AUGUST: 'สิงหาคม',
            SEPTEMBER: 'กันยายน',
            OCTOBER: 'ตุลาคม',
            NOVEMBER: 'พฤศจิกายน',
            DECEMBER: 'ธันวาคม',
        },
    }
};
