import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-docs',
  templateUrl: './docs.component.html',
  styleUrls: ['./docs.component.scss']
})
export class DocsComponent implements OnInit {

  public contentHeader: object

  constructor() { }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'Document',
      actionButton: false,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'TW-MSS DASHBOARD document',

          },
        ]
      }
    }
  }

}
