import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Subject} from 'rxjs';
import {MaintenanceType} from '../../models/type';

@Injectable({
    providedIn: 'root'
})

export class MaintenanceService {

    public maintenanceSubject = new Subject<any>();
    public maintenanceSignatureSubject = new Subject<any>();

    constructor(
        private _http: HttpClient
    ) {
    }

    async maintenanceList(
        {
            placeId = null,
            buildingId = null,
            floorId = null,
            assetId = null,
            maintenanceType = null,
            maintenanceStatus = null,
            comments = null,
            startDate = null,
            endDate = null,
            name = null,
            orderBy = null,
            page = 1,
            limit = 10
        }) {
        const url = `${environment.apiUrl}/maintenances`;
        let params = new HttpParams()
            .set('orderBy', orderBy)
            .set('page', String(page))
            .set('limit', String(limit));
        if (placeId) {
            params = params.set('placeId', placeId);
        }
        if (buildingId) {
            params = params.set('buildingId', buildingId);
        }
        if (floorId) {
            params = params.set('floorId', floorId);
        }
        if (assetId) {
            params = params.set('assetId', assetId);
        }
        if (maintenanceType) {
            params = params.set('maintenanceType', maintenanceType);
        }
        if (maintenanceStatus) {
            params = params.set('maintenanceStatus', maintenanceStatus);
        }
        if (comments) {
            params = params.set('comments', comments);
        }
        if (startDate) {
            params = params.set('startDate', startDate);
        }
        if (endDate) {
            params = params.set('endDate', endDate);
        }
        if (name) {
            params = params.set('name', name);
        }
        return await this._http
            .get(url, {params})
            .toPromise();
    }

    async getMaintenance(id: string) {
        const url = `${environment.apiUrl}/maintenances/${id}`;
        return await this._http
            .get(url)
            .toPromise();
    }

    async editMaintenanceType(id: string, type: MaintenanceType) {
        const data = {maintenanceType: type};
        const url = `${environment.apiUrl}/maintenances/${id}`;
        return await this._http
            .patch(url, data)
            .toPromise();
    }

    async editMaintenanceStatus(id: string, data: any) {
        const url = `${environment.apiUrl}/maintenances/${id}`;
        return await this._http
            .patch(url, data)
            .toPromise();
    }

    async deleteMaintenance(id: string, data: any) {
        const url = `${environment.apiUrl}/maintenances/${id}`;
        return await this._http
            .patch(url, data)
            .toPromise();
    }

    async getMaintenanceHistory(
        {
            identifierNumber = '',
            maintenanceType = '',
            maintenanceStatus = '',
            orderBy = 'createAt:desc',
            page = 1,
            limit = 10,
        }): Promise<any> {
        const url = `${environment.apiUrl}/mobile-platform/maintenances`;
        const params = new HttpParams()
            .set('identifierNumber', identifierNumber.toString())
            .set('orderBy', orderBy)
            .set('maintenanceType', maintenanceType)
            .set('maintenanceStatus', maintenanceStatus)
            .set('page', page.toString())
            .set('limit', limit.toString());
        return this._http
            .get(url, {params})
            .toPromise()
            .then((response) => response);
    }

    async createSignatures(data: any) {
        const url = `${environment.apiUrl}/maintenance-signatures`;
        return await this._http
            .post(url, data)
            .toPromise();
    }
}
