<!-- Sticker Asset Default -->
<div class="con-card" *ngIf="typeSticker === 'asset'">
    <div class="con-body">
        <h5>{{ data.name }}</h5>
        <div class="con-qr">
            <div class="b-qr">
                <app-qrcode [textQrCode]="data.qrcode" [height]="130" [width]="130"></app-qrcode>
            </div>
        </div>
        <div class="con-title">
            <p>{{ data?.place?.name }}</p>
            <p>{{ data?.location?.name }}</p>
        </div>
        <div class="con-detail">
            <div class="con-text">
                <p>Model</p>
                <p>
                    {{
                        data?.assetInfos[0]?.model === "undefined" ||
                        data?.assetInfos[0]?.model === undefined ||
                        data?.assetInfos[0]?.model === "null" ||
                        data?.assetInfos[0]?.model === null
                            ? "-"
                            : data?.assetInfos[0]?.model
                    }}
                </p>
            </div>
            <div class="con-text">
                <p>BTU</p>
                <p>
                    {{
                        data?.assetInfos[0]?.btu === "undefined" ||
                        data?.assetInfos[0]?.btu === undefined ||
                        data?.assetInfos[0]?.btu === "null" ||
                        data?.assetInfos[0]?.btu === null
                            ? "-"
                            : data?.assetInfos[0]?.btu
                    }}
                </p>
            </div>
            <div class="con-text">
                <p>Type</p>
                <p>{{ data?.group }}</p>
            </div>
        </div>
    </div>
    <div class="con-footer">
        <div class="con-logo">
            <img src="assets/images/logo/logo_twmss.png" alt=""/>
            <p>TECHNICAL WATER CO., LTD</p>
        </div>
        <!-- <div class="con-address">
                <p>
                    301/856 ซ.รามคำแหง 68 หัวหมาก บางกะปิ กรุงเทพฯ 10240
                    <br/> Tel. 02-735-3022
                    Email: Technicalwater2015@gmail.com
                </p>
            </div> -->
    </div>
</div>
<!-- End Sticker Asset Default -->

<!-- Sticker type asset-group -->
<div class="con-card" *ngIf="typeSticker === 'floor' && data?.qrcode">
    <div class="con-body asset-group">
        <h5>ชั้น {{ data.name }}</h5>
        <div class="con-qr">
            <div class="b-qr">
                <app-qrcode [textQrCode]="data.qrcode" [height]="130" [width]="130"></app-qrcode>
            </div>
        </div>
        <div class="con-title">
            <p>แบบประเมินความพึงพอใจ</p>
            <p>{{ data?.building?.place?.name || '-' }}</p>
        </div>
        <div class="con-detail">
            <div class="con-text">
                <p>อาคาร:</p>
                <p>{{ data?.building?.name || '-' }}</p>
            </div>
        </div>
    </div>
    <div class="con-footer">
        <div class="con-logo">
            <img src="assets/images/logo/logo_twmss.png" alt=""/>
            <p>TECHNICAL WATER CO., LTD</p>
        </div>
    </div>
</div>
<!-- End Sticker Asset group-->
