import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from 'environments/environment';
import {User, Role} from 'app/auth/models';
import {ToastrService} from 'ngx-toastr';
import { PlaceService } from '../../services/api/place.service';


@Injectable({providedIn: 'root'})
export class AuthenticationService {
    // public
    public currentUser: Observable<User>;

    // private
    private currentUserSubject: BehaviorSubject<User>;

    /**
     *
     * @param {HttpClient} _http
     * @param {ToastrService} _toastrService
     */
    constructor(
        private _http: HttpClient,
        private _toastrService: ToastrService,
        private _placeService: PlaceService,


    ) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();

    }

    // getter: currentUserValue
    public get currentUserValue(): any {
        return this.currentUserSubject.getValue();
    }

    /**
     *  Confirms if user is Admin
     */
    get isAdmin() {
        return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
    }

    /**
     *  Confirms if user is Admin_read_only
     */
    get isAdmin_read_only() {
        return this.currentUser && this.currentUserSubject.value.role === Role.Admin_read_only;
    }

    /**
     *  Confirms if user is Manager
     */
    get isManager() {
        return this.currentUser && this.currentUserSubject.value.role === Role.Manager;
    }

    /**
     *  Confirms if user is Manager_read_only
     */
    get isManager_read_only() {
        return this.currentUser && this.currentUserSubject.value.role === Role.Manager_read_only;
    }

    /**
     *  Confirms if user is Technician
     */
    get isTechnician() {
        return this.currentUser && this.currentUserSubject.value.role === Role.Technician;
    }

    /**
     *  Confirms if user is User
     */
    get isUser() {
        return this.currentUser && this.currentUserSubject.value.role === Role.User;
    }

    /**
     * User login
     *
     * @param username
     * @param password
     * @returns user
     */
    login(username: string, password: string) {
        return this._http
            .post<any>(`${environment.apiUrl}/auth/signin`, {username, password})
            .pipe(
                map(user => {
                    if (user && user?.tokens) {
                        localStorage.setItem('currentUser', JSON.stringify(user));
                        this.currentUserSubject.next(user);
                    }
                    return user;
                })
            );
    }

    /**
     * User logout
     *
     */
    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('currentPlace');
        localStorage.removeItem('placeList');
        // notify
        this.currentUserSubject.next(null);
    }


    getRequestUserToken(): Promise<any> {
        const url = `${environment.apiUrl}/dashboard-platform/auth/request-user-token`;
        return this._http.get(url).toPromise();
    }

    async getStoragePlaceList() {
        try {
            const place: any = await this._placeService.placeList({
                orderBy: 'createdAt:desc',
                page: 1,
                limit: 9999
            });
    
            if (place) {
                this._placeService.saveStoragePlaceList(place)
            }
            console.log('login = place', place)
            console.log('get login getStoragePlaceList', this._placeService.getStoragePlaceList())
        } catch (error) {
            return;
        }
    
    }


    // private setUserLogin(result: any) {
    //     // login successful if there's a jwt token in the response
    //     if (result.user && result.tokens) {
    //         // TODO : Check role if user check event role
    //         // TODO : Remove this
    //         // console.log(result.user.role)
    //         if (result.user.role === 'user') {
    //             result.user.role = 'staff';
    //         }
    //         result.user.token = result.tokens.access.token;
    //         result.user.refreshToken = result.tokens.refresh.token;
    //         // store user details and jwt token in local storage to keep user logged in between page refreshes
    //         localStorage.setItem('currentUser', JSON.stringify(result.user));
    //         // notify
    //         this.currentUserSubject.next(result.user);
    //     }
    //     return result.user;
    // }

}
