import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { BrowserQRCodeReader, BrowserQRCodeSvgWriter } from '@zxing/library';

@Component({
    selector: 'app-qrcode',
    templateUrl: './qrcode.component.html',
    styleUrls: ['./qrcode.component.scss']
})
export class QrcodeComponent implements OnInit {

    @Input() textQrCode;
    @Input() width = 1000;
    @Input() height = 1000;

    @ViewChild('qrcode', {static: true}) qrcodeCanvas: ElementRef | undefined;

    constructor() {
    }

    ngOnInit(): void {
        this.generateQRCode(this.textQrCode);
    }

    generateQRCode(text): void {
        const writer = new BrowserQRCodeSvgWriter();
        const svgElement = writer.write(text, this.width, this.height);
        const svgO = svgElement.outerHTML;
        const updatedSvgString = svgO.replace('<svg ', '<svg style="margin: -8px" ');
        this.qrcodeCanvas.nativeElement.innerHTML = updatedSvgString;
    }

//   generateQRCode(text): void {
//     const writer = new BrowserQRCodeSvgWriter();
//     const svgElement = writer.write(text, this.width, this.height);

//     // Add viewBox to SVG to introduce a 10px margin
//     const widthWithMargin = this.width + 20; // 10px on each side
//     const heightWithMargin = this.height + 20; // 10px on each side
//     svgElement.setAttribute('viewBox', `-10 -10 ${widthWithMargin} ${heightWithMargin}`);

//     // Set the x and y attributes to -10 to move the QR code into the center of the viewBox
//     const rectElement = svgElement.querySelector('rect');
//     // if (rectElement) {
//     //   rectElement.setAttribute('x', '-10');
//     //   rectElement.setAttribute('y', '-10');
//     // }

//     this.qrcodeCanvas.nativeElement.innerHTML = svgElement.outerHTML;
// }
}
