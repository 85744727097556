import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// module
import { CoreCommonModule } from '@core/common.module';
import { SharedModule } from 'app/shared/shared.module';

// component
import { AssetPinComponent } from './asset-pin/asset-pin.component';
import { DataNotFoundComponent } from './data-not-found/data-not-found.component';
import { ImageCropperComponent } from './image-cropper/image-cropper.component';
import { MapComponent } from './map/map.component';
import { QrcodeComponent } from './qrcode/qrcode.component';

// cards
import { StickerCardComponent } from './cards/sticker-card/sticker-card.component';

// modals
import { SignatureModalComponent } from './modal/signature-modal/signature-modal.component';
import { SignatureComponent } from './signature/signature.component';

@NgModule({
    declarations: [
        // component
        AssetPinComponent,
        DataNotFoundComponent,
        ImageCropperComponent,
        MapComponent,
        QrcodeComponent,
        SignatureComponent,

        // cards
        StickerCardComponent,

        // modal
        SignatureModalComponent,
    ],
    imports: [
        FormsModule,
        CommonModule,

        // module
        CoreCommonModule,
        SharedModule,
    ],
    exports: [
        // component
        AssetPinComponent,
        DataNotFoundComponent,
        ImageCropperComponent,
        MapComponent,
        QrcodeComponent,
        SignatureComponent,

        // cards
        StickerCardComponent,

        // modal
        SignatureModalComponent,
    ],
})

export class ComponentsModule {
}
