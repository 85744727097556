import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

// ng ngx bootstrap
import { ImageCroppedEvent, base64ToFile } from 'ngx-image-cropper';

interface Crop {
    base64: string;
    file: any;
}

@Component({
    selector: 'app-image-cropper',
    templateUrl: './image-cropper.component.html',
    styleUrls: ['./image-cropper.component.scss']
})

export class ImageCropperComponent implements OnInit {

    @ViewChild('inputPlan') fileInput: any;
    @Output() confirmCrop: EventEmitter<any> = new EventEmitter<Crop>();

    imageChangedEvent: any = '';
    croppedImage: any = '';
    croppedImageWidth = 0;
    croppedImageHeight = 0;
    fileCrop: any;

    constructor() {
    }

    ngOnInit(): void {
    }

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        this.fileCrop = base64ToFile(this.croppedImage);

        this.croppedImageWidth = event.width;
        this.croppedImageHeight = event.height;
    }

    imageLoaded() {
        // show cropper
        console.log('imageLoaded');
    }

    cropperReady() {
        // cropper ready
        console.log('cropperReady');

    }

    loadImageFailed() {
        console.log('loadImageFailed');
        // show message
    }

    openFileInput() {
        if (this.fileInput) {
            this.fileInput.nativeElement.click();
        }
    }

    confirm() {
        if (!this.croppedImage) {
            return;
        }
        const data: Crop = {
            base64: this.croppedImage,
            file: this.fileCrop
        };

        this.confirmCrop.emit(data);
        this.imageChangedEvent = null;
    }
}
