import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-sticker-card',
    templateUrl: './sticker-card.component.html',
    styleUrls: ['./sticker-card.component.scss']
})

export class StickerCardComponent implements OnInit {

    @Input() typeSticker = 'asset';
    @Input() data: any;

    constructor() {
    }

    ngOnInit(): void {
    }
}
