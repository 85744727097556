import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class FilesService {

    constructor(
        private _http: HttpClient
    ) {
    }

    base64toFile(base64String: string, filename: string): File {
        // Split the base64 string into two parts
        const parts = base64String.split(';base64,');
        const contentType = parts[0].split(':')[1];
        const byteCharacters = atob(parts[1]);

        // Convert the byte characters into a typed array
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        // Create Blob from the byte array
        const blob = new Blob([byteArray], {type: contentType});

        // Create File object
        return new File([blob], filename, {type: contentType});
    }

    async deleteImage(image: { url: string | File; }) {
        const url = `${environment.apiUrl}/dashboard-platform/files`;
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        const options = {
            headers: headers,
            body: JSON.stringify(image) // แปลงอ็อบเจ็กต์ image เป็นสตริง JSON
        };
        return await this._http.request('delete', url, options)
            .toPromise();
    }
}
