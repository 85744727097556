<div class="header-main-menu d-flex justify-content-between align-items-center w-100 m-auto">
    <div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
        <!-- Navbar brand -->
        <ul class="nav navbar-nav flex-row">
            <li class="nav-item">
                <a class="navbar-brand" [routerLink]="['/']">
                    <span class="brand-logo">
                        <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="26"/>
                    </span>
<!--                    <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2>-->
                </a>
            </li>
        </ul>
        <!--/ Navbar brand -->
    </div>

    <div class="navbar-container d-flex content">
        <div class="main-menu-wrapper d-flex align-items-center justify-content-between w-100">
            <div class="d-flex align-items-center">
                <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
                <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
                    <li class="nav-item">
                        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
                            <span [data-feather]="'menu'" [class]="'ficon'"></span>
                        </a>
                    </li>
                </ul>
                <!--/ Menu Toggler -->

                <!-- <div class="menu-desktop-only">
                    <div routerLink="/dashboard"   routerLinkActive="active" class="menu-main-header">
                        {{ 'MENU.DASHBOARD' | translate}}
                    </div>
                </div> -->

                <!-- <div class="menu-desktop-only">
                    <div routerLink="/home"   routerLinkActive="active" class="menu-main-header">
                        {{ 'MENU.HOME' | translate}}
                    </div>
                </div> -->

				<!-- <div class="menu-desktop-only ml-3">
                    <div routerLink="/chat-demo"   routerLinkActive="active" class="menu-main-header">
                        Text to Voice Chat (DEMO)
                    </div>
                </div> -->

                <!-- <div class="menu-desktop-only ml-3" ngbDropdown routerLinkActive="active">
                    <div ngbDropdownToggle class="menu-main-header" id="dropdownMenuTrade">
                        {{ 'MENU.DOCUMENT' | translate}}
                    </div>
                    <div ngbDropdownMenu aria-labelledby="dropdownMenuTrade">
                        <a ngbDropdownItem routerLinkActive="active" class="py-1 border-bottom" routerLink="/document">
                            <div class="text-white-main font-weight-bolder mb-25">Docs</div>
                            <small>Start building smart contracts</small>
                        </a>

                        <a ngbDropdownItem routerLinkActive="active" routerLink="/privacy"
                           class="py-1 border-bottom">
                            <div class="text-white-main font-weight-bolder mb-25">Privacy</div>
                            <small>Read the privacy policy and
                                <br/>
                                term of services
                            </small>
                        </a>

                    </div>
                </div> -->

            </div>

            <!--/ Language selection -->

            <ul class="nav navbar-nav align-items-center ml-auto">

                <!-- Toggle skin -->
                <li *ngIf="coreConfig.layout.enableToggleTheme" class="nav-item d-none d-lg-block mr-50">
                    <button type="button" class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
                        <span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather">

                        </span>
                    </button>
                </li>
                <!--/ Toggle skin -->

                <!-- select Language -->
                <li *ngIf="coreConfig.layout.enableMultiLanguage" ngbDropdown class="nav-item dropdown dropdown-language mx-2">
                    <a class="round mr-025 dropdown-toggle nav-link" id="dropdown-flag" ngbDropdownToggle>
                      <i class="flag-icon flag-icon-{{ languageOptions[_translateService.currentLang].flag }} img-language"></i>
                      <span class="selected-language text-white-main tx-13 text-uppercase">{{ languageOptions[_translateService.currentLang].title }}</span>
                    </a>
                    <div ngbDropdownMenu aria-labelledby="dropdown-flag">
                      <a class="p-2" *ngFor="let lang of _translateService.getLangs()" ngbDropdownItem (click)="setLanguage(lang)">
                        <i class="flag-icon flag-icon-{{ languageOptions[lang].flag }} img-language"></i>
                        <span class="text-white-main tx-13 text-uppercase align-bottom">{{ languageOptions[lang].title }}</span>
                      </a>
                    </div>
                  </li>
                <!--/ select Language -->

                <!-- select chain -->
                <li *ngIf="coreConfig.layout.enableSwitchSupportChain && account && !isWrongNetwork" ngbDropdown class="nav-item dropdown ml-xl-auto icons hide-mobile nav-select-chain">
                    <a ngbDropdownToggle class="nav-link menu-icons {{router.url === '/home' ? 'text-white-nav':''}}" id="current-chain" *ngIf="currentChain">
                        <img [src]="currentChain.imgUrl" height="26" width="26" class="align-top" title="{{currentChain.networkName}}">
                        <span id="current-chain-name" style="display: none;">{{currentChain.networkName}}</span>
                    </a>
                    <div *ngIf="singletonService.supportChains && currentChain" ngbDropdownMenu aria-labelledby="dropdown-flag">
                        <li *ngFor="let chain of singletonService.supportChains | findAllBy: 'enabled'" (click)="switchChain(chain)" ngbDropdownItem class="nav-item {{chain.soon ? 'disabled':''}}" >
                            <a class="nav-link d-flex justify-content-start {{chain.soon ? 'disabled':''}}" [ngClass]="currentChain.networkName == chain.networkName?'active-chain' : ''" href="javascript:void(0)"><img [src]="chain.imgUrl" height="24" width="24" class="mr-2">
                              {{chain.networkName}} {{chain.soon ? '(Soon)':''}}
                            </a>
                        </li>
                    </div>
                </li>
                <!-- select chain -->


                <!-- Connect Wallet Button -->
                <li class="nav-item mr-0 ml-0">
                    <button *ngIf="false" type="button"
                            class="nav-btn btn btn-gradient-primary btn-icon-text btn-connect-wallet-nav round"
                            (click)="openConnectWalletModal(modalConnectWallet)">
                        <i class="mdi mdi-wallet"></i> &nbsp;
                        <!-- {{'common.connect_wallet_button' | translate}} -->
                        Connect wallet
                    </button>

                    <button *ngIf="account && !isWrongNetwork" type="button"
                            class="nav-btn btn btn-connect-wallet-nav btn-gradient-primary btn-icon-text py-1 round"
                            (click)="logout(logoutModal)">
                        <!-- <i class="mdi mdi-wallet"></i> -->
                        <i class="mdi mdi-wallet"></i> &nbsp;{{account.replace(account.substring(4, 38), "...")}}
                    </button>
                    <button *ngIf="account && isWrongNetwork" type="button"
                            (click)="openSwitchChainModal(switchChainModal)"
                            class="nav-btn btn btn-danger btn-icon-text round">
                        <!-- {{'common.wrong_network' | translate}} -->
                        Wrong network
                    </button>
                </li>

                <!--/ profile Button -->
                <li *ngIf="account && !isWrongNetwork" ngbDropdown class="nav-item dropdown-user">
                    <a
                            class="nav-link dropdown-toggle dropdown-user-link"
                            id="dropdown-user"
                            ngbDropdownToggle
                            id="navbarUserDropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                    >

                        <span class="avatar" >
                            <img
                                class="round"
                                src="../../../../assets/images/portrait/small/user.png"
                                alt="avatar"
                                height="40"
                                width="40" />
                        </span>
                    </a>
                    <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">


                        <a ngbDropdownItem   routerLink="/profile"
                           class="py-1 border-bottom">
                            <div class="text-white-main font-weight-bolder mb-25">Your profile</div>
                            <small>Edit your profile</small>
                        </a>



                    </div>
                </li>

            </ul>

        </div>
    </div>
</div>

<ng-template #modalConnectWallet let-modal>
    <!-- <div class="modal-header modal-header-primary p-2">
      <h4 class="modal-title text-focus-main">
        Connect Wallet
      </h4>
      <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div> -->

    <div class="modal-body p-0">
        <div class="d-flex justify-content-center align-items-center flex-column">
            <button class="btn-connect-wallet-modal" (click)="connectWalletWithMetamask();modal.dismiss()">
                <div class="img-connect-wallet">
                    <img src="./../../../../assets/images/wallets/metamask.png" width="50">
                </div>
                <div class="title-connect-wallet">Metamask</div>
                <div class="desc-connect-wallet">Connect wallet with Metamask</div>
            </button>
            <button class="btn-connect-wallet-modal" (click)="connectWalletConnect();modal.dismiss()">
                <div class="img-connect-wallet">
                    <img src="./../../../../assets/images/wallets/walletconnect-logo.png"width="50">
                </div>
                <div class="title-connect-wallet">WalletConnect</div>
                <div class="desc-connect-wallet">Connect wallet with WalletConnect</div>
            </button>
            <!-- <button class="btn-connect-wallet-modal" (click)="connectBitkubNext();modal.dismiss()">
                <div class="img-connect-wallet">
                    <img src="./../../../../assets/images/wallets/bitkub-next-logo.svg" width="50">
                </div>
                <div class="title-connect-wallet">Bitkub Next</div>
                <div class="desc-connect-wallet">Connect wallet with Bitkub Next</div>
            </button> -->
        </div>
    </div>

</ng-template>

<ng-template #logoutModal let-modal>
    <div class="text-right" style="margin-right: 15px; margin-top: 15px">
        <button type="button" class="close" (click)="modal.close('close')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="text-center">

            <!--<img src="../../../assets/icons/unicorn.png" width="72"/> -->
            <h4>
                <!-- {{ 'common.disconnect' | translate }} -->
                disconnect
            </h4>
            <p style="margin-top: 8px">
                <!-- {{ 'common.disconnect_confirm' | translate }} -->
                Are you sure you want to disconnect ?
            </p>
        </div>
    </div>
    <div class="text-center" style="margin-top: 5px;margin-bottom: 50px">
        <button type="button" class="btn btn-secondary" (click)="modal.close('close')">
            <!-- {{ 'common.cancel' | translate }} -->
            Cancel
        </button>
        <button type="button" style="margin-left: 10px" class="btn btn-primary" (click)="modal.close('logout')">
            <!-- {{ 'common.disconnect_yes' | translate }} -->
            Yes, disconnect
        </button>
    </div>
</ng-template>

<ng-template #switchChainModal let-modal>
    <div class="text-right" style="margin-right: 15px; margin-top: 15px">
        <button type="button" class="close" (click)="modal.close('close')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="text-center">

            <!-- <img src="./../../../../assets/images/tokens/KUB.png" width="72"/> -->
            <p style="margin-top: 25px">
                <!-- {{ 'common.switch' | translate }} -->

                <!-- 'Switch to Bitkub Chain ?' -->
                'Switch to Bitkub Chain - Testnet?'
            </p>
        </div>
    </div>
    <div class="text-center" style="margin-top: 20px;margin-bottom: 30px">
        <button type="button" class="btn btn-secondary" (click)="modal.close('close')">
            <!-- {{ 'common.nope' | translate }} -->
            nope
        </button>
        <!-- <button type="button" style="margin-left: 10px" class="btn btn-primary"
                (click)="singletonService.switchToBitkubChain();modal.close('close');">
            yes
        </button> -->
        <button type="button" style="margin-left: 10px" class="btn btn-primary"
                (click)="singletonService.switchToBitkubChain_testnet();modal.close('close');">
            yes
        </button>
    </div>
</ng-template>



