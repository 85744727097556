import { Inject, Injectable } from '@angular/core';
import { BROWSER_STORAGE } from '../../../helper/storage';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class PlaceService {

    private readonly PLACE = 'currentPlace';
    private readonly PLACE_LIST = 'placeList';

    public place$ = new Subject<any>();

    public placeSubject = new Subject<any>();

    constructor(
        @Inject(BROWSER_STORAGE) private storage: Storage,
        private _http: HttpClient,
    ) {
    }

    public saveCurrentPlace(place: any): void {
        const currentPlace = JSON.stringify(place);
        // this.place$.next(place);
        return this.storage.setItem(this.PLACE, currentPlace);
    }

    public saveStoragePlaceList(places: any): void {
        const placeList = JSON.stringify(places);
        // this.place$.next(places);
        return this.storage.setItem(this.PLACE_LIST, placeList);
    }

    public getCurrentPlace(): string {
        return JSON.parse(this.storage.getItem(this.PLACE));
    }

    public getStoragePlaceList(): any {
        return JSON.parse(this.storage.getItem(this.PLACE_LIST));
    }

    async placeList({
                        name = null,
                        address = null,
                        orderBy = null,
                        page = 1,
                        limit = 10
                    }) {
        const url = `${environment.apiUrl}/places`;
        let params = new HttpParams()
            .set('orderBy', orderBy)
            .set('page', String(page))
            .set('limit', String(limit));
        if (name) {
            params = params.set('name', name);
        }
        if (address) {
            params = params.set('address', address);
        }
        return await this._http
            .get(url, {params})
            .toPromise();
    }

    async createPlace(data: any) {
        const url = `${environment.apiUrl}/places`;
        return await this._http
            .post(url, data)
            .toPromise();
    }

    async editPlace(id: any, data: any) {
        const url = `${environment.apiUrl}/places/${id}`;
        return await this._http
            .patch(url, data)
            .toPromise();
    }

    async getPlace(id: string) {
        const url = `${environment.apiUrl}/places/${id}`;
        return await this._http
            .get(url)
            .toPromise();
    }

    async deletePlace(id: string) {
        const url = `${environment.apiUrl}/places/${id}`;
        return await this._http
            .delete(url)
            .toPromise();
    }

    async deletePlaceImage(data: any) {
        const url = `${environment.apiUrl}/places/images`;
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        const options = {
            headers: headers,
            body: JSON.stringify(data)
        };
        return await this._http
            .request('delete', url, options)
            .toPromise();
    }
}
