import { Injectable } from '@angular/core';

// ng ngx bootstrap
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// service
import { SharedModalService } from '../shared/services/shared-modal.service';

import { Subject } from 'rxjs';

interface Data {
    title?: string;
    detail?: string;
    btnConfirm?: string;
    status?: string;
    type?: string;
}

interface SignatureData {
    signatureType?: string;
    type?: string;
}

@Injectable({
    providedIn: 'root'
})

export class ModalService {

    public modal: any;
    public modalData$ = new Subject<Data>();

    public modalSignature: any;
    public modalSignatureData$ = new Subject<SignatureData>();

    constructor(
        private _translateService: TranslateService,
        private _modalService: NgbModal,
        private _sharedModalService: SharedModalService,
    ) {
    }

    openModal(data: Data) {
        const modalTemplate = this._sharedModalService.modalTemplate;
        this.modal = this._modalService.open(modalTemplate,
            {
                size: 'lg',
                backdrop: 'static',
                centered: true
            });

        return this.modal.result.then((result: any) => {
                return result;
            },
        ).catch((error: any) => {
            console.error('error modal', error);
            return false;
        });

    }

    openModalSignature(data: SignatureData) {
        this.modalSignatureData$.next(data);
        const modalSignatureTemplate = this._sharedModalService.modalSignatureTemplate;
        this.modalSignature = this._modalService.open(modalSignatureTemplate,
            {
                size: 'lg',
                backdrop: 'static',
                centered: true
            });

        return this.modalSignature.result.then((result: any) => {
                return result;
            },
        ).catch((error: any) => {
            console.error('error modal signature', error);
            return false;
        });
    }

    closeModalSignature(value: boolean) {
        this.modalSignature.close(value);
    }
}
